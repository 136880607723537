import React from "react";
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";

import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";

import { clearTemporaryStorage } from 'helpers/helpers'

function Header() {
	const history = useHistory();
	const dispatch = useDispatch()

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	function logout() {
		dispatch({ type: 'USER_LOGGED_OUT', payload: null })
		clearTemporaryStorage()
		history.push('/login')
	}

	return (
		<>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logoSm} alt="" width="30" />
								</span>
								<span className="logo-lg">
									<img src={logoDark} alt="" width="30" />
								</span>
							</Link>
						</div>

						<button
							type="button"
							onClick={() => {
								tToggle();
							}}
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
							id="vertical-menu-btn"
						>
							<i className="uil-bars fz-20" />
						</button>
					</div>

					<div className="d-flex">

						<LanguageDropdown />

						<Dropdown className="d-none d-lg-inline-block ms-1">
							<button
								type="button"
								onClick={() => {
									toggleFullscreen();
								}}
								className="btn header-item noti-icon waves-effect"
								data-toggle="fullscreen"
							>
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<div className="d-none d-lg-inline-block ms-1 dropdown" onClick={logout}>
							<button type="button" className="btn header-item noti-icon waves-effect">
								<div className="d-flex">
									<div className="vertical-center me-2">
										{JSON.parse(localStorage.getItem('authUser')).username}
									</div>
									<i className="uil-sign-out-alt"></i>
								</div>
							</button>
						</div>

					</div>
				</div>
			</header>
		</>
	);
};

export default Header
