import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { POST, PGET, DELETE } from 'helpers/api'
import JoditEditor from 'jodit-react'


function Notification() {
	const { t } = useTranslation();

	const [selectedIds, setSelectedIds] = useState([]);
	const [pos, setPos] = useState([]);
	const [lat, setLat] = useState("")
	const [cyr, setCyr] = useState("")
	const [ru, setRu] = useState("")
	const [deleteId, setDeleteId] = useState(0)
	const [data, setData] = useState([])
	const [userIds, setUserIds] = useState([]);
	const [message, setMessage] = useState({
		'ru': '',
		'lat': '',
		'cyr': '',
	})


	const editor = useRef(null);
	const [content, setContent] = useState('');

	const config = useMemo(() => ({
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			placeholder: 'Start typings...'
		}),
		['']
	);

	useEffect(() => {
		setMessage({
		  ru,
		  lat,
		  cyr,
		});
	  }, [ru, lat, cyr]);

	async function getData() {
		const response = await PGET('/services/admin/api/notification', {}, { loader: true })
		if (response) {
			setData(response.data)
		}
	}

	async function deleteItem(deleteId) {
		await DELETE('/services/admin/api/notification/' + deleteId, { loader: true })
		getData()
	}

	const handleCheckAll = () => {
		if (selectedIds.length === pos.length) {
		  // Uncheck all if all are already checked
		  setSelectedIds([]);
		  setUserIds([])
		} else {
		  // Check all
		  const allIds = pos.map(item => item.id);
		  setSelectedIds(allIds);
		  setUserIds(allIds)
		}
	  };

	const handleCheckboxChange = (id) => {
		let updatedSelectedIds;
		if (selectedIds.includes(id)) {
		  // Remove the ID if it's already in the selectedIds array
		  updatedSelectedIds = selectedIds.filter(selectedId => selectedId !== id);
		} else {
		  // Add the ID if it's not in the selectedIds array
		  updatedSelectedIds = [...selectedIds, id];
		}
		setSelectedIds(updatedSelectedIds);
		setUserIds(updatedSelectedIds); // Update userIds with the latest selectedIds
	};

	function saveNotification() {
		var sendData = {
			'message': JSON.stringify(message),
			'userIds': userIds
		}
		console.log(sendData)
		POST("/services/admin/api/notification", sendData).then(() => {
			toast.success(t('saved_successfully'))
			getData()
		})
	}

	async function getPosData() {
		const response = await PGET('/services/admin/api/getAllPos', {}, { loader: true })
		if (response) {
			setPos(response.data)
		}
	}

	useEffect(() => {
		getPosData()
		getData()
	}, []) 

	return (
		<>
			<div className="pt-40 relative">
				<div className="h-table-settings pr-2 mb-1">
					<div className="py-1">
						<div className="card mb-3">
							<div className="card-body">
								<div className="d-flex justify-content-between">
									<h5 className="settings-title">{t('NOTIFICATIONS')}</h5><br></br>
								</div>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">LOTIN</h6>
									</div>
									<div className="vertical-center w-500px">
										<JoditEditor
											ref={editor}
											config={config}
											tabIndex={1} // tabIndex of textarea
											onBlur={newContent => setLat(newContent)} // preferred to use only this option to update the content for performance reasons
											onChange={newContent => {}}
										/>			
									</div>
								</div>
								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">KIRIL</h6>
									</div>
									<div className="vertical-center w-500px">
										<JoditEditor
											ref={editor}
											config={config}
											tabIndex={1} // tabIndex of textarea
											onBlur={newContent => setCyr(newContent)} // preferred to use only this option to update the content for performance reasons
											onChange={newContent => {}}
										/>	
									</div>
								</div>
								<div className="setting-block mb-3">
									<div className="vertical-center">
										<h6 className="m-0 fw-500">RU</h6>
									</div>
									<div className="vertical-center w-500px">
									<JoditEditor
											ref={editor}
											config={config}
											tabIndex={1} // tabIndex of textarea
											onBlur={newContent => setRu(newContent)} // preferred to use only this option to update the content for performance reasons
											onChange={newContent => {}}
										/>	
									</div>
								</div>

								<div className="setting-block mb-3 border" style={{padding: '10px'}}>
									<button onClick={handleCheckAll} style={{padding: '5px'}}>
										All
									</button>
									<br></br>
									{pos.map((item) => (
										<div key={item.id} style={{ 
											display: 'inline-flex', 
											alignItems: 'center', 
											width: '25%' // Change this value to control the percentage width
										  }}>
											<input
												type="checkbox"
												value={item.id}
												id={`checkbox-${item.id}`}
												checked={selectedIds.includes(item.id)}
												onChange={() => handleCheckboxChange(item.id)}
											/>
											<label htmlFor={`checkbox-${item.id}`}>{item.login}</label>
										</div>
									))}
								</div>

								<div className="d-flex">
									<button button="submit" className="btn btn-primary text-uppercase" onClick={saveNotification}>{t('SAVE')}</button>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
				
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th style={{width: '10%'}}>№</th>
									<th style={{width: '80%'}}>{t('NOTIFICATION_TEXT')}</th>
									<th style={{width: '10%'}}>{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td>UZ: {JSON.parse(item.message).lat} <br />KR: {JSON.parse(item.message).cyr} <br />RU: {JSON.parse(item.message).ru}</td>
										<td>
											<div className="table-action-button table-action-danger-button" onClick={() => deleteItem(item.id)}>
												<i className="uil uil-trash-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Notification
