export let exportMenu = [
	{
		url: "/dashboard",
		i18n: "HOME",
		icon: "uil-home-alt",
		active: false,
		adminOnly: true
	},
	{
		url: "/admin/clients",
		i18n: "ADMIN",
		icon: "uil-users-alt",
		active: false,
		adminOnly: false
	},
	{
		url: "/pos",
		i18n: "POSES",
		icon: "uil-shop",
		active: false,
		adminOnly: false
	},
	{
		url: "/payments",
		i18n: "PAYMENTS",
		icon: "uil-money-bill",
		active: false,
		adminOnly: true
	},
	{
		url: "/admin/debt/excel",
		i18n: "DEBTOR",
		icon: "uil-file-alt",
		active: false,
		adminOnly: true
	},
	{
		url: "/products",
		i18n: "PRODUCTS",
		icon: "uil-shopping-cart",
		active: false,
		adminOnly: true
	},
	{
		url: "/accountManager",
		i18n: "CLIENT_ADMIN",
		icon: "uil-user",
		active: false,
		adminOnly: true
	},
	{
		url: "/cashboxSettings",
		i18n: "CASHBOX_SETTINGS",
		icon: "uil-setting",
		active: false,
		adminOnly: true
	},
	{
		url: "/notification",
		i18n: "NOTIFICATIONS",
		icon: "uil-message",
		active: false,
		adminOnly: true
	},
]