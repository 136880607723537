import React from 'react'
import ReactPaginate from 'react-paginate'

function Pagination({pageCount, paginate}) {
	return (
		<>
			{pageCount > 1 &&
				<ReactPaginate
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={3}
					pageRangeDisplayed={3}
					onPageChange={paginate}
					containerClassName={'pagination'}
					activeClassName={'active'}
					pageClassName={'page-item'}
				/>
			}
		</>
	)
}

export default Pagination
