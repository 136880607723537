import React from "react"
import { Redirect } from "react-router-dom"
// Dashboard
import DashboardIndex from "../pages/Dashboard/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"

// GENERAL
import posIndex from "../pages/general/poses/Index"
import posCreateUpdate from "../pages/general/poses/CreateUpdate"

import suppliersIndex from "../pages/general/suppliers/Index"

import clientsIndex from "../pages/general/clients/Index"
import myOrganizationUpdate from "../pages/general/myOrganization/CreateUpdate"

import productsIndex from "../pages/general/products/Index"
import productsCreateUpdate from "../pages/general/products/CreateUpdate"

import accountManagerIndex from "../pages/general/accountManager/Index"

import cashboxSettings from "../pages/general/cashboxSettings/Settings"

import notification from "../pages/general/notification/Notification"

import payments from "../pages/general/payments/Index"

// ADMIN
import adminClientsIndex from "../pages/admin/clients/Index"
import debtorExcel from "../pages/admin/debtorExcel/Index"
import test from "../pages/test"

const userRoutes = [
	{ path: "/dashboard", component: DashboardIndex },

	// GENERAL
	{ path: "/pos", component: posIndex },
	{ path: "/pos-create/:ownerLogin", component: posCreateUpdate },
	{ path: "/pos-update/:id", component: posCreateUpdate },

	{ path: "/clients", component: clientsIndex },

	{ path: "/my-organization", component: myOrganizationUpdate },

	{ path: "/suppliers", component: suppliersIndex },

	{ path: "/products", component: productsIndex },
	{ path: "/products-create", component: productsCreateUpdate },
	{ path: "/products-update/:id", component: productsCreateUpdate },

	{ path: "/accountManager", component: accountManagerIndex },

	{ path: "/cashboxSettings", component: cashboxSettings },

	{ path: "/notification", component: notification },

	{ path: "/payments", component: payments },


	// ADMIN
	{ path: "/admin/clients", component: adminClientsIndex },
	{ path: "/admin/debt/excel", component: debtorExcel },
	{ path: "/test", component: test },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
	// 404 route not found
	{ path: "*", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
	{ path: "/login", component: Login },
]

export { userRoutes, authRoutes }